<template>
    <div class="mt-5">

        <div class="d-flex justify-content-center mt-4">
            <b-button-group class="bg-grey-theme w-75">
                <b-button
                        @click="clickPage(item)"
                        v-for="item in buttonList"
                        :key="item.id"
                        :class="
            item.isActive
              ? 'btn-page-violence px-5'
              : 'btn-no-page-violence px-5'
          "
                >{{ item.name }}</b-button
                >
            </b-button-group>
        </div>
        <div class="container" style="max-width: 1200px">
            <Dynamic1 v-if="currentPage === 1"></Dynamic1>
            <Dynamic2 v-if="currentPage === 2"></Dynamic2>
        </div>
    </div>
</template>

<script>
    import Dynamic1 from "../components/Dynamic1";
    import Dynamic2 from "../components/Dynamic2";
    export default {
        name: "DynamicGraph",
        components: {Dynamic2, Dynamic1},
        data () {
            return {
                buttonList: [
                    { id: 1, name: "รายงานการถูกกระทำรุนแรง", isActive: true },
                    { id: 2, name: "รายงานการตั้งครรภ์ไม่พึงประสงค์", isActive: false },
                ],
                currentPage: 1,
            }
        },
        created() {
            const page = this.$route.query.id ?  this.$route.query.id: 1;
            this.currentPage = parseInt(page);

            this.buttonList.forEach(x=>{
                if(x.id === this.currentPage) x.isActive =true;
                else x.isActive = false
            })
        },
        methods:{
            clickPage(model) {
                this.$router.push({
                    path: 'report-dynamic',
                    query: { id: model.id },
                });
                window.location.reload();
            },
        }
    }
</script>

<style scoped>

</style>
