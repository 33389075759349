<template>
    <div>
        <h2 class="text-center mt-5" style="color: rgba(99, 104, 236, 1)">
            รายงานการถูกกระทำความรุนแรง
        </h2>
        <b-row class="mt-5">
            <b-col sm="12" md="12" lg="12" xl="12">
                <div class="main-form-oscc">
                    <b-row>
                        <b-col class="body-form-oscc mr-3 font-weight-bold">
                            กรองข้อมูล
                        </b-col>
                    </b-row>
                    <b-row class="pr-5">
                        <b-col cols="6" class="body-form-oscc w-100">
                            <b-form-group label-for="input1" class="mb-0">
                                <template #label> เขต</template>
                                <b-form-select
                                        v-model="main_filter.area"
                                        class="form-control"
                                        id="input1"
                                        :options="options_area"
                                        v-on:change="changeFilter"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group label-for="input2" class="mb-0">
                                <template #label> จังหวัด</template>
                                <b-form-select
                                        text-field="name"
                                        value-field="code"
                                        v-model="main_filter.province"
                                        class="form-control"
                                        id="input2"
                                        :options="options_province"
                                        v-on:change="setHospital"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="pr-5">
                        <b-col cols="6" class="body-form-oscc w-100">
                            <b-form-group class="mb-0">

                                <label>ประเภทของโรงพยาบาล</label>
                                <b-form-select
                                        v-if="main_filter.area != '13'"
                                        v-model="hospital_type"
                                        class="form-control"
                                        @change="setHospital"
                                >
                                    <b-form-select-option value=""
                                    >เลือกประเภททั้งหมดของโรงพยาบาล
                                    </b-form-select-option
                                    >

                                    <b-form-select-option
                                            v-for="(item, i) in hospitalType"
                                            :key="i"
                                            :value="item"
                                    >{{ item.key }}
                                    </b-form-select-option
                                    >
                                </b-form-select>

                                <b-form-select
                                        v-else
                                        v-model="hospital_type"
                                        class="form-control"
                                        @change="setHospital"
                                >
                                    <b-form-select-option value=""
                                    >เลือกประเภททั้งหมดของโรงพยาบาล
                                    </b-form-select-option
                                    >

                                    <b-form-select-option
                                            v-for="(item, i) in thirteenType"
                                            :key="i"
                                            :value="item"
                                    >{{ item.key }}
                                    </b-form-select-option
                                    >
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc w-100">
                            <b-form-group label-for="input3" class="mb-0">
                                <template #label> โรงพยาบาล</template>
                                <b-form-select
                                        text-field="full_name"
                                        value-field="hospcode"
                                        v-model="main_filter.hospital"
                                        class="form-control"
                                        id="input3"
                                        :options="filterhospital"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group label-for="input4" class="mb-0">
                                <template #label> เพศ</template>
                                <b-form-select
                                        v-model="main_filter.sex"
                                        class="form-control"
                                        id="input4"
                                        :options="options_sex"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc w-100">
                            <b-form-group label-for="input5" class="mb-0">
                                <template #label> ช่วงอายุ (ปี)</template>
                                <b-row>
                                    <b-col cols="5">
                                        <b-form-input
                                                v-model="main_filter.age_min"
                                                class="form-control"
                                                id="input5"
                                        ></b-form-input>
                                    </b-col>
                                    <span style="position: relative; top: 5px">-</span>
                                    <b-col cols="5">
                                        <b-form-input
                                                v-model="main_filter.age_max"
                                                class="form-control"
                                                id="input6"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pr-0">
                            <div style="background-color: rgba(244, 247, 253, 1)">
                                <b-form-group label-for="input3" class="mb-0 pl-5 mt-5">
                                    <template #label>
                                        <span style="padding-left: 50px"> วันที่เกิดเหตุ </span>
                                    </template>
                                    <div class="row align-items-center">
                                        <div class="col-1">
                                            <b-form-radio
                                                    style="position: absolute; bottom: 0"
                                                    v-model="main_filter.selected_date"
                                                    value="incident"
                                            ></b-form-radio>
                                        </div>
                                        <div class="col-5">
                                            <v-menu
                                                    v-model="menu1"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            outlined
                                                            rounded
                                                            class="mt-2"
                                                            dense
                                                            v-model="computedStartEventDateFormatted"
                                                            append-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="event_date_start"
                                                        @input="menu1 = false"
                                                        locale="th-th"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                        <div class="col-1">
                                            <span style="position: absolute; bottom: 0">-</span>
                                        </div>
                                        <div class="col-5">
                                            <v-menu
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            outlined
                                                            rounded
                                                            class="mt-2"
                                                            dense
                                                            v-model="computedEndEventDateFormatted"
                                                            append-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="event_date_end"
                                                        @input="menu2 = false"
                                                        locale="th-th"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                </b-form-group>
                                <br/>
                            </div>
                        </b-col>
                        <b-col cols="6" class="pl-0">
                            <div style="background-color: rgba(243, 243, 243, 1)">
                                <b-form-group label-for="input4" class="mb-0 mt-5">
                                    <template #label>
                                        <span style="padding-left: 50px"> วันที่มาโรงพยาบาล </span>
                                    </template>
                                    <div class="row align-items-center">
                                        <div class="col-1">
                                            <b-form-radio
                                                    style="position: absolute; bottom: 0"
                                                    v-model="main_filter.selected_date"
                                                    value="hospital"
                                            ></b-form-radio>
                                        </div>
                                        <div class="col-5">
                                            <v-menu
                                                    v-model="menu3"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            outlined
                                                            rounded
                                                            class="mt-2"
                                                            dense
                                                            v-model="computedStartHospitalDateFormatted"
                                                            append-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="hospital_date_start"
                                                        @input="menu3 = false"
                                                        locale="th-th"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                        <div class="col-1">
                                            <span style="position: absolute; bottom: 0">-</span>
                                        </div>
                                        <div class="col-5">
                                            <v-menu
                                                    v-model="menu4"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            outlined
                                                            rounded
                                                            class="mt-2"
                                                            dense
                                                            v-model="computedEndHospitalDateFormatted"
                                                            append-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="hospital_date_end"
                                                        @input="menu4 = false"
                                                        locale="th-th"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                    <!--                  <b-row>-->
                                    <!--                    <b-form-radio class="front-radio" v-model="main_filter.selected_date" value="hospital"></b-form-radio>-->
                                    <!--                    <b-col cols="5" class="px-0">-->
                                    <!--                      <date-picker id="input9" v-model="hospital_date_start" placeholder="ระบุวัน/เดือน/ปี" value-type="format" format="DD-MM-YYYY" :editable="false" :clearable="false"></date-picker>-->
                                    <!--                      <span style="position: relative;left: 25px">-</span>-->
                                    <!--                    </b-col>-->
                                    <!--                    <b-col>-->
                                    <!--                      <date-picker id="input10" v-model="hospital_date_end" placeholder="ระบุวัน/เดือน/ปี" value-type="format" format="DD-MM-YYYY" :editable="false" :clearable="false"></date-picker>-->
                                    <!--                    </b-col>-->
                                    <!--                  </b-row>-->
                                </b-form-group>
                                <br/>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <b-row class="mt-5" style="margin-bottom: 5%">
            <b-col sm="6" md="6" lg="6" xl="6">
                <div class="main-form-oscc" style="min-height: 600px">
                    <b-row class="pl-5 pr-5">
                        <b-col class="body-form-oscc mr-3 font-weight-bold">
                            ส่วนของข้อมูลรอง
                            <b-form-select
                                    value-field="form"
                                    text-field="form_th"
                                    v-model="second_select"
                                    class="form-control mt-4"
                                    :options="options_second"
                            ></b-form-select>
                            <hr/>
                            <div
                                    v-for="(item, index) in dynamic_checkbox_option"
                                    :key="index"
                            >
                                <div
                                        class="checkbox-border px-2 py-2 my-3"
                                        v-if="item.form == second_select"
                                >
                                    <div class="form-check violence-checkbox text-left">
                                        <b-form-checkbox
                                                :id="item.key"
                                                @change="setData(item.id, item.table_column, $event)"
                                                v-model="item.checked"
                                                :name="item.key"
                                                :unchecked-value="false"
                                                disabled-field="true"
                                                :disabled="item.disabled"
                                        >
                                            {{ item.table_column_th }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="6">
                <div class="main-form-oscc" style="min-height: 600px">
                    <b-row class="pl-5 pr-5">
                        <b-col class="body-form-oscc mr-3 font-weight-bold">
                            ข้อมูลหลัก
                            <b-form-select
                                    v-model="main_select"
                                    class="form-control mt-4"
                                    v-on:change="changeData"
                            >
                                <option
                                        v-for="(option, idx) in options_main"
                                        :key="idx"
                                        :value="option"
                                >
                                    {{ "(" + option.form_th + ") " + option.table_column_th }}
                                </option>
                            </b-form-select>
                            <hr/>
                            ข้อมูลรอง
                            <div
                                    v-for="(item, index) in dynamic_checkbox_selected"
                                    :key="index"
                            >
                                <b-row>
                                    <b-col cols="6">
                                        <div class="checkbox-border px-2 py-2 my-3">
                                            {{ item.table_column_th }}
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <multiselect
                                                class="my-3"
                                                :options="item.option"
                                                v-model="main_filter.filter[item.id]"
                                                select-label=""
                                                deselectLabel=""
                                                :close-on-select="true"
                                                :multiple="true"
                                        >
                                        </multiselect>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-5">
                        <b-col cols="1"></b-col>
                        <b-col>
                            <b-button
                                    @click="sendData(true)"
                                    class="w-100 mb-3"
                                    variant="primary"
                                    style="border-radius: 20px; color: white"
                            >แสดง
                            </b-button
                            >
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="10"></b-col>
            <b-col cols="2">
                <b-button
                        @click="excel"
                        class="w-100"
                        variant="primary"
                        style="border-radius: 20px; color: white"
                >ส่งออก Excel
                </b-button
                >
            </b-col>
        </b-row>

        <!--    <b-row class="mt-5">-->
        <!--      <b-col sm="12" md="12" lg="12" xl="12">-->
        <!--        <div class="main-form-oscc">-->
        <!--          <b-row>-->
        <!--            <b-col class="body-form-oscc mr-3 font-weight-bold text-center">-->
        <!--              <span-->
        <!--                >รายงานจำนวนผู้ถูกกระทำรุนแรง จำแนกตาม{{-->
        <!--                  main_select.table_column_th ? main_select.table_column_th : ""-->
        <!--                }}</span-->
        <!--              >-->
        <!--              <Dynamic_1_1-->
        <!--                :graph="graph"-->
        <!--                :load_chart="load_chart"-->
        <!--                :main_filter="main_filter"-->
        <!--                :color="color"-->
        <!--                @success="clearOldData"-->
        <!--              ></Dynamic_1_1>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </div>-->
        <!--      </b-col>-->
        <!--    </b-row>-->

        <!--    <b-row class="mt-5">-->
        <!--      <b-col sm="12" md="12" lg="12" xl="12">-->
        <!--        <div class="main-form-oscc">-->
        <!--          <b-row>-->
        <!--            <b-col class="body-form-oscc mr-3 font-weight-bold text-center">-->
        <!--              <span>รายงานจำนวนผู้ถูกกระทำรุนแรง รายโรงพยาบาล</span>-->
        <!--              <Dynamic_1_2-->
        <!--                :graph="graph"-->
        <!--                :load_chart="load_chart"-->
        <!--                :main_filter="main_filter"-->
        <!--                :color="color"-->
        <!--                @success="clearOldData"-->
        <!--              ></Dynamic_1_2>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </div>-->
        <!--      </b-col>-->
        <!--    </b-row>-->

        <b-row class="mt-5">
            <b-col sm="12" md="12" lg="12" xl="12">
                <div class="main-form-oscc" style="border-radius: 20px">
                    <b-row>
                        <b-col class="ont-weight-bold text-center">
                            <table
                                    class="table table-striped w-100"
                                    v-if="Object.keys(this.data_table_filter).length > 0"
                            >
                                <thead class="header-table-oscc">
                                <tr class="text-center only-border">
                                    <th scope="col" rowspan="2">ลำดับ</th>
                                    <th scope="col" rowspan="2">รายโรงพยาบาล</th>
                                    <th
                                            scope="col"
                                            :colspan="Object.keys(this.data_table_filter).length"
                                    >
                                        {{
                                        main_select.table_column_th
                                            ? main_select.table_column_th
                                            : ""
                                        }}
                                    </th>
                                    <th scope="col" rowspan="2">รวม</th>
                                </tr>
                                <tr class="text-center">
                                    <th
                                            class="text-center"
                                            v-for="(val, i) in data_table_filter"
                                            v-bind:key="i"
                                    >
                                        {{ val }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="(val, i, index) in data_table"
                                        v-bind:key="i"
                                        :tabindex="i"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="text-left">{{ i }}</td>
                                    <td
                                            class="text-center"
                                            v-for="(value, index) in data_table_filter"
                                            v-bind:key="index"
                                    >
                                        {{ val[value] }}
                                    </td>
                                    <td class="text-center">
                                        {{ sum_total(val) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
import Multiselect from "vue-multiselect";
import {mapState} from "vuex";
// import Dynamic_1_1 from "./charts/dynamic_1_1";
// import Dynamic_1_2 from "./charts/dynamic_1_2";
import moment from "moment";

export default {
    components: {
        // Dynamic_1_2,
        // Dynamic_1_1,
        Multiselect,
    },
    computed: {
        ...mapState({
            dynamic_checkbox_option: (state) => {
                return state.dynamic.dynamic_checkbox_option;
            },
            options_main: (state) => {
                return state.dynamic.options_main;
            },
            options_second: (state) => {
                return state.dynamic.options_second;
            },
            dynamic_checkbox: (state) => {
                return state.dynamic.dynamic_checkbox;
            },
            dynamic_checkbox_selected: (state) => {
                return state.dynamic.dynamic_checkbox_selected;
            },
            trigger: (state) => {
                return state.dynamic.trigger;
            },
            computedStartEventDateFormatted() {
                if (!this.event_date_start) return null;
                const [year, month, day] = this.event_date_start.split("-");
                return `${day}/${month}/${parseInt(year) + 543}`;
            },
            computedEndEventDateFormatted() {
                if (!this.event_date_end) return null;
                const [year, month, day] = this.event_date_end.split("-");
                return `${day}/${month}/${parseInt(year) + 543}`;
            },
            computedStartHospitalDateFormatted() {
                if (!this.hospital_date_start) return null;
                const [year, month, day] = this.hospital_date_start.split("-");
                return `${day}/${month}/${parseInt(year) + 543}`;
            },
            computedEndHospitalDateFormatted() {
                if (!this.hospital_date_end) return null;
                const [year, month, day] = this.hospital_date_end.split("-");
                return `${day}/${month}/${parseInt(year) + 543}`;
            },
        }),
    },
    created() {
        this.showLoading();
        this.$store.dispatch("MasterData/setProvince").then((res) => {
            this.options_province = res.data;
            this.options_province = [
                {name: "ทุกจังหวัด", code: ""},
                ...this.options_province,
            ];
            let data = {
                code: "",
                type: "",
                province: this.main_filter.province ? this.main_filter.province : "",
            };
            this.$store.dispatch("MasterData/setHospCode", data).then((res) => {
                this.options_hosp = res.data;
                this.options_hosp = [
                    {full_name: "ทุกโรงพยาบาล", hospcode: ""},
                    ...this.options_hosp,
                ];
                this.hideLoading();
            });
        });
        this.$store.dispatch("dynamic/getOptionDynamic", "violence");
        // this.setStartDate();
    },
    watch: {
        trigger: function (newVal) {
            this.changeChecked(newVal);
        },
    },
    data() {
        return {
            // trigger : true,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            main_select: 1,
            second_select: 1,
            event_date_start: "",
            event_date_end: "",
            hospital_date_start: "",
            hospital_date_end: "",
            hospital_type: "",
            main_filter: {
                section: "violence",
                area: "",
                province: "",
                hospital: "",
                sex: "",
                age_min: "",
                age_max: "",
                selected_date: "incident",
                event_date_start: "",
                event_date_end: "",
                hospital_date_start: "",
                hospital_date_end: "",
                main_option_id: "",
                filter: [],
            },
            options_area: [
                {text: "ทุกเขตสุขภาพ", value: ""},
                {text: "เขตสุขภาพที่ 1", value: "1"},
                {text: "เขตสุขภาพที่ 2", value: "2"},
                {text: "เขตสุขภาพที่ 3", value: "3"},
                {text: "เขตสุขภาพที่ 4", value: "4"},
                {text: "เขตสุขภาพที่ 5", value: "5"},
                {text: "เขตสุขภาพที่ 6", value: "6"},
                {text: "เขตสุขภาพที่ 7", value: "7"},
                {text: "เขตสุขภาพที่ 8", value: "8"},
                {text: "เขตสุขภาพที่ 9", value: "9"},
                {text: "เขตสุขภาพที่ 10", value: "10"},
                {text: "เขตสุขภาพที่ 11", value: "11"},
                {text: "เขตสุขภาพที่ 12", value: "12"},
                {text: "เขตสุขภาพที่ 13", value: "13"},
            ],
            hospitalType: [
                {key: "โรงพยาบาลศูนย์_A", value: "A"},
                {key: "โรงพยาบาลทั่วไป_S", value: "S"},
                {key: "โรงพยาบาลทั่วไปขนาดเล็ก_M1", value: "M1"},
                {key: "โรงพยาบาลชุมชนแม่ข่าย_M2", value: "M2"},
                {key: "โรงพยาบาลชุมชนขนาดใหญ่_F1", value: "F1"},
                {key: "โรงพยาบาลชุมชนขนาดกลาง_F2", value: "F2"},
                {key: "โรงพยาบาลชุมชนขนาดเล็ก_F3", value: "F3"},
            ],
            thirteenType: [
                {key: "สำนักงานสาธารณสุขจังหวัด", value: "สำนักงานสาธารณสุขจังหวัด"},
                {key: "สำนักงานสาธารณสุขอำเภอ", value: "สำนักงานสาธารณสุขอำเภอ"},
                {key: "สถานีอนามัย", value: "สถานีอนามัย"},
                {key: "สถานบริการสาธารณสุขชุมชน", value: "สถานบริการสาธารณสุขชุมชน"},
                {key: "โรงพยาบาลศูนย์", value: "โรงพยาบาลศูนย์"},
                {key: "โรงพยาบาลทั่วไป", value: "โรงพยาบาลทั่วไป"},
                {key: "โรงพยาบาลชุมชน", value: "โรงพยาบาลชุมชน"},
                {key: "โรงพยาบาลนอก สป.สธ.", value: "โรงพยาบาลนอก สป.สธ."},
            ],
            options_province: [],
            options_hosp: [],
            filterhospital: [],
            options_sex: [
                {text: "ทั้งหมด", value: ""},
                {text: "เพศชาย", value: "เพศชาย"},
                {text: "เพศหญิง", value: "เพศหญิง"},
                {text: "เพศทางเลือก", value: "เพศทางเลือก"},
            ],
            graph: {
                main_option_id: "",
                filter: [],
            },
            load_chart: false,
            data_table: [],
            data_table_filter: [],
            color: [
                "rgba(159, 191, 255, 1)",
                "rgba(255, 150, 150, 1)",
                "rgba(169, 147, 255, 1)",
                "rgba(151, 233, 233, 1)",
                "rgba(255, 221, 156, 1)",
                "rgba(91, 69, 223, 1)",
                "rgba(69, 121, 223, 1)",
                "rgba(8, 146, 146, 1)",
                "rgba(80, 91, 128, 1)",
                "rgba(243, 230, 137, 1)",
            ],
        };
    },
    mounted() {
    },
    methods: {
        setHospital() {
            if (this.hospital_type && !this.main_filter.province)
                this.filterhospital = this.options_hosp.filter(x => (x.type_code == this.hospital_type.value))
            else if (this.hospital_type && this.main_filter.province)
                this.filterhospital = this.options_hosp.filter(x => (x.type_code == this.hospital_type.value && x.province_code == this.main_filter.province))
            else if (!this.hospital_type && this.main_filter.province)
                this.filterhospital = this.options_hosp.filter(x => (x.province_code == this.main_filter.province))
        },
        setData(id, item, val) {
            this.$store.dispatch("dynamic/setData", {name: item, value: val});
            !val ? (this.main_filter.filter[id] = []) : "";
            this.$forceUpdate();
        },
        changeData(val) {
            this.main_filter.filter = [];
            this.$store.dispatch("dynamic/changeData", val.table_column);
            this.$forceUpdate();
        },
        changeChecked(val) {
            this.$store.dispatch("dynamic/changeChecked", val);
            this.$forceUpdate();
        },
        sendData(val) {
            if (this.main_select && this.main_select.id) {
                this.graph.main_option_id = this.main_select.id;
                this.main_filter.filter.forEach((items, idk) => {
                    items.forEach((item) => {
                        this.graph.filter.push({option_id: idk, value: item});
                    });
                });
                if (this.main_filter.selected_date == "incident") {
                    this.main_filter.event_date_start = this.event_date_start
                        ? moment(this.event_date_start).format("YYYY-MM-DD")
                        : "";
                    this.main_filter.event_date_end = this.event_date_end
                        ? moment(this.event_date_end).format("YYYY-MM-DD")
                        : "";
                } else if (this.main_filter.selected_date == "hospital") {
                    this.main_filter.hospital_date_start = this.hospital_date_start
                        ? moment(this.hospital_date_start).format("YYYY-MM-DD")
                        : "";
                    this.main_filter.hospital_date_end = this.hospital_date_end
                        ? moment(this.hospital_date_end).format("YYYY-MM-DD")
                        : "";
                }
                if (val) {
                    this.$forceUpdate();
                    this.load_chart = this.load_chart ? false : true;
                    let data = {
                        'main_filter': this.main_filter,
                        'main_option_id': this.graph.main_option_id,
                        'filter': this.graph.filter,
                    }
                    this.$store.dispatch('dynamic/getGraph', data).then(res => {
                        this.clearOldData(res.data.data_table, res.data.data_table_filter)
                    })
                }
            } else {
                alert("กรุณาเลือกข้อมูหลัก");
            }
        },
        clearOldData(data_table, data_table_filter) {
            this.data_table = data_table;
            this.data_table_filter = data_table_filter;
            this.graph = {
                main_option_id: "",
                filter: [],
            };
            this.hideLoading();
        },
        excel() {
            this.showLoading();
            this.sendData(false);
            let data = {
                main_filter: this.main_filter,
                main_option_id: this.graph.main_option_id,
                filter: this.graph.filter,
                name_file: "violence",
            };
            this.$store.dispatch("dynamic/getExcel", data).then((res) => {
                this.hideLoading();
                window.open(res);
            });
        },
        sum_total(obj) {
            return Object.values(obj).reduce(function (sum, addon) {
                return sum + addon;
            }, 0);
        },
        setStartDate() {
            let christianYear = moment().format("YYYY");
            let buddhishYear = (parseInt(christianYear) + 543).toString();
            let thaiDate = moment()
                .format(
                    "DD-MM-YYYY"
                        .replace("YYYY", buddhishYear)
                        .replace("YY", buddhishYear.substring(2, 4))
                )
                .replace(christianYear, buddhishYear);
            this.event_date_start = thaiDate;
            this.event_date_end = thaiDate;
            this.hospital_date_start = thaiDate;
            this.hospital_date_end = thaiDate;
        },
        changeFilter() {
            this.showLoading();
            this.$store.dispatch("MasterData/setProvince").then((res) => {
                this.options_province = res.data;
                this.options_province = [
                    {name: "ทุกจังหวัด", code: ""},
                    ...this.options_province.filter(
                        (data) => this.main_filter.area == data.area_code
                    ),
                ];
                this.main_filter.province = "";
                this.main_filter.hospital = "";
                let data = {
                    code: this.main_filter.area,
                    type: "",
                    province: "",
                };
                this.$store.dispatch("MasterData/setHospCode", data).then((res) => {
                    this.options_hosp = res.data;
                    this.options_hosp = [
                        {full_name: "ทุกโรงพยาบาล", hospcode: ""},
                        ...this.options_hosp,
                    ];
                    this.hideLoading();
                });
            });
        },
        changeProvince() {
            // this.showLoading();
            // let data = {
            //   code: "",
            //   type: "",
            //   province: this.main_filter.province ? this.main_filter.province : "",
            // };
            // this.$store.dispatch("MasterData/setHospCode", data).then((res) => {
            //   this.options_hosp = res.data;
            //   this.options_hosp = [
            //     { full_name: "ทุกโรงพยาบาล", hospcode: "" },
            //     ...this.options_hosp,
            //   ];
            //   this.main_filter.hospital = "";
            //   this.hideLoading();
            // });
        },
        showLoading() {
            this.$loading.show({
                background: this.$swal.fire({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading();
                    },
                }),
                animation: true,
            });
        },
        hideLoading() {
            this.$loading.hide();
            this.$swal.hideLoading();
        },
    },
};
</script>
<style>
ul.multiselect__content {
    padding-left: 0 !important;
}
</style>

<style scoped>
div.front-radio {
    display: inline !important;
}

.front-radio {
    position: relative;
    top: 3px;
    left: 20px;
    width: 10%;
    margin: 0;
}

thead.header-table-oscc tr th {
    border: 1px solid white;
    background-color: rgba(25, 46, 88, 1);
    color: white;
}

table tr.only-border th:first-child {
    border-radius: 20px 0 0 0;
}

table tr.only-border th:last-child {
    border-radius: 0 20px 0 0;
}

.main-form-oscc {
    background: #ffffff;
    border: 0.5px solid #a8a8a8;
    box-sizing: border-box;
    box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
    border-radius: 5px;
}

.body-form-oscc {
    padding-left: 6%;
    margin-top: 13px;
}

b-col {
    margin-bottom: 20px;
}
</style>
